/*regular version*/
@font-face {
	font-family: 'U8';
	src: url('../Font-U8/U8-Regular.otf'); 
	src: 
		local('U8'),
		local('U8'),
		url('../Font-U8/U8-Regular.otf') 
		format('opentype');
}

/*bold version*/
@font-face {
	font-family: 'U8';
	src: url('../Font-U8/U8-Bold.otf'); 
	src: 
		local('U8 Bold'),
		local('U8-Bold'),
		url('../Font-U8/U8-Bold.otf') 
		format('opentype');
	font-weight: bold;
}

// fonts
$font-family: 'U8', $font-family-base;
$font-size-12: 12px;
$font-sm: 14px;
$font-body: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-24: 24px;
$font-size-28: 28px;
$font-size-30: 30px;
$font-md: 38px;
$font-lg: 50px;
$fw-500: 500;
$fw-bold: 700;
