.header {
    .navbar-toggler { 
        &:focus {
            box-shadow: unset;
        }

        i {
            font-size: 40px;
            @include media-breakpoint-up(sm) {
                font-size: 62px;
            }
        }
    }

    img {
        width: 127px;
    }

    a {
        color: $brand-white;
        padding: 16.5px 24px;
        &:hover {
            color: $brand-white;
        }
    }

    li:last-child {
        a {
            color: $brand-dark-10;
            padding: 17px 24px;
            &:hover {
                color: $brand-dark-10;
            }
        }
    }
}
