.col-custom {
    &-wide {
        @include media-breakpoint-up(lg) {
            width: 52%;
            flex: 0 0 auto;
        }
        img {
            object-fit: cover;
        }
    }
    &-slim {
        @include media-breakpoint-up(lg) {
            width: 48%;
            flex: 0 0 auto;
        }
    }
}

.no-gutter {
    --bs-gutter-x: unset;
}

.back-link {
    color: $brand-white-20;
    cursor: pointer;
    &:hover {
        color: $brand-white-20;
    }
}

.appointment {
    &-form {
        color: $brand-white;
        padding: 32px 27px;
        @include media-breakpoint-up(lg) {
            padding: 32px 60px 32px 50px;
        }
        @include media-breakpoint-up(xl) {
            padding: 32px 120px 32px 100px;
        }

        h2 {
            font-size: $font-size-30;
            margin-top: 60px;
            @include media-breakpoint-up(lg) {
                margin-top: 40px;
            }
        }

        .form {
            &-label {
                font-size: $font-sm;
            }
            &-control {
                padding: 10px 14px;
                font-size: $font-sm;
                @include media-breakpoint-up(sm) {
                    font-size: $font-body;
                }
                &:focus {
                    box-shadow: unset;
                    border-color: unset;
                }
            }
        }

        .input {
            &-box {
                margin-bottom: 20px;
            }
            &-description {
                .form-control {
                    min-height: 154px;
                }
            }
        }

        .copyright {
            font-size: $font-sm;
        }
    }
}

.book-appointment-logo {
    width: 127px;
}
