.project-category {
    .back-link {
        @include media-breakpoint-up(lg) {
            position: absolute;
            top: 54px;
        }
    }
    h2 {
        font-size: $font-size-30;
    }
    &-row {
        row-gap: 50px;
        overflow-x: scroll;
        display: grid;
        grid-template-columns: auto auto auto;
        margin: 50px 0 14px;
        @extend .custom-hide-scroll-bar;
        &.hide-scrollbar {
            @include media-breakpoint-up(lg) {
                overflow-x: hidden;
            }
        }
        @include media-breakpoint-up(lg) {
            margin: 60px 0 38px;
        }

        img {
            border-radius: 10px;
            height: 174px;
            object-fit: cover;
            @include media-breakpoint-up(md) {
                height: 269px;
            }
        }
    }
    &-card {
        width: 230px;
        @include media-breakpoint-up(md) {
            width: 380px;
        }
    }
}