@import './fonts';

// colors
$brand-yellow: #FEEF3C;
$brand-dark: #0E0E10;
$brand-dark-10: #1B1C20;
$brand-dark-20: #383A47;
$brand-white: #FFFFFF;
$brand-white-20: #F8F8FA;
$brand-grey: #E8E9ED;
$brand-grey-10: #797B89;
$brand-grey-20: #475467;
$brand-grey-30: #474958;

$im: !important;
