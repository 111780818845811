.projects-photos {
    row-gap: 50px;
    overflow-x: scroll;
    max-height: 1300px;
    @extend .custom-hide-scroll-bar;

    img {
        border-radius: 10px;
        height: 174px;
        object-fit: cover;
        @include media-breakpoint-up(md) {
            height: 269px;
        }
    }
}