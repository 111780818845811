.video-responsive {
    text-align: center;
    margin-bottom: 80px;
    @include media-breakpoint-up(lg) {
        margin-bottom: 130px;
    }
}
  
.video-responsive iframe {
    width: 100%;
    @include media-breakpoint-up(lg) {
        width: 80%;
    }
    @include media-breakpoint-up(xxl) {
        width: 65%;
    }
}