.btn {
    padding: 17px 24px;
    font-size: $font-body;
    cursor: pointer;
    letter-spacing: 0.25px;
    border-radius: 12px;

    &:focus {
        box-shadow: unset $im;
    }

    &-brand {
        &-primary {
            background-color: $brand-yellow;
            border-color: $brand-yellow;
            color: $brand-dark-10;
            &:hover {
                color: $brand-dark-10;
            }
        }
    }
}
