footer {
    color: $brand-white-20;
    padding: 64px 0 32px;

    .logo {
        width: 182px;
    }

    .footer {
        &-links {
            row-gap: 12px;
            width: 75%;
            margin: 0 auto;
            @include media-breakpoint-up(sm) {
                width: 100%;
            }
        }
        &-socials {
            @extend .team-member-socials;
        }
    }

    .line {
        border-bottom: 1px solid $brand-grey-20;
        margin: 32px 16px;
        @include media-breakpoint-up(md) {
            margin: 64px 0 32px;
        }
    }

    a {
        color: $brand-white-20;
        text-decoration: none;
        margin-right: 32px;
        white-space: nowrap;
    }

    .location {
        font-size: $font-sm;
        line-height: 21px;
    }
}
