.custom {
    &-heading-2 {
        font-size: $font-size-28;
        font-weight: $fw-bold;

        @include media-breakpoint-up(sm) {
            font-size: $font-md;
        }
    }
    &-p-text {
        color: $brand-grey;
        @include media-breakpoint-up(md) {
            font-size: $font-size-18;
        }
    }
    &-hide-scroll-bar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            display: none;
            @include media-breakpoint-up(lg) {
                display: block;
                width: 0.5rem;
            }
            &-track {
                box-shadow: inset 0 0 6px $brand-yellow;
                border-radius: 20px;
            }
            &-thumb {
                background-color: $brand-white;
                outline: 1px solid $brand-yellow;
                border-radius: 20px;
            }
        }

        @include media-breakpoint-up(lg) {   /* Firefox */
            scrollbar-width: auto;
            scrollbar-color: $brand-white $brand-yellow;
        }

        @include media-breakpoint-between(lg, xxl) {
            padding-bottom: 30px;
        }

        @media screen and (min-width: 1400px) {
            &::-webkit-scrollbar {
                display: none;
            }
            scrollbar-width: none;  /* Firefox */
        }
    }
}

.herobanner {
    &-heading {
        color: $brand-white;
        font-size: $font-md;
        font-weight: $fw-bold;
        letter-spacing: 0.04em;
        line-height: 130%;

        @include media-breakpoint-up(md) {
            font-size: $font-lg;
        }
    }

    &-text {
        @extend .custom-p-text;
        @include media-breakpoint-up(xl) {
            margin-right: 90px;
        }

        &-box {
            @include media-breakpoint-up(xl) {
                width: 90%;
            }
        }
    }

    &-row {
        padding: 56px 0;
        @include media-breakpoint-up(md) {
            padding: 100px 0;
        }
        @include media-breakpoint-up(xl) {
            padding: 100px 0 100px 40px;
        }
    }

    .image-box {
        width: 80%;
    }
}

.about-us {
    padding-bottom: 50px;
    @include media-breakpoint-up(lg) {
        padding-bottom: 158px;
    }

    &-row {
        padding: 0 6px;
        @include media-breakpoint-up(md) {
            padding: 0 40px;
        }
    }

    &-heading {
        @extend .custom-heading-2;
    }

    &-text {
        @extend .custom-p-text;
    }

    img {
        width: 100%;
        @include media-breakpoint-between(sm, lg) {
            width: unset;
        }
    }
}

.values {
    padding-bottom: 52px;
    @include media-breakpoint-up(md) {
        padding-bottom: 75px;
    }

    &-text {
        font-size: $font-size-24;
        line-height: 28px;
        letter-spacing: 0.8px;
        color: $brand-dark-20;
        margin-bottom: 51px;
        @include media-breakpoint-up(lg) {
            font-size: 48px;
            line-height: 55px;
        }
        @include media-breakpoint-up(xl) {
            width: 85%;
        }
    }

    &-images {
        overflow-x: hidden;
        margin-bottom: 88px;

        .swipe { 
            &-left {
                position: relative;
                animation-name: animateLeft;
                animation-direction: alternate;
                animation-iteration-count: infinite;
                animation-timing-function: linear;

                @keyframes animateLeft {
                    0%   {left: 0;}
                    100% {left: calc(-2809px + 90vw);}
                }
            }
            &-right {
                @extend .swipe-left;
                animation-name: animateRight;

                @keyframes animateRight {
                    0%   {right: 0;}
                    100% {right: calc(-2809px + 90vw);}
                }
            }
        }

        @include media-breakpoint-up(md) {
            padding-left: 36px;
            margin-bottom: 24px;
        }
        @include media-breakpoint-up(lg) {
            padding-left: 78px;
        }
        &-bottom {
            @include media-breakpoint-up(md) {
                margin-bottom: 102px;
                padding-right: 36px;
            }
        }

        img {
            margin-right: 40px;
        }
    }

    .brands {
        row-gap: 40px;
        @include media-breakpoint-up(lg) {
            column-gap: 75px;
        }
    }
}

.projects {
    padding: 48px 0;
    
    @include media-breakpoint-up(md) {
        padding: 75px 0 108px;
    }

    &-section {
        padding: 0 6px;
        @include media-breakpoint-up(lg) {
            padding: 0 40px;
        }
    }
    
    h2 {
        @extend .custom-heading-2;
        letter-spacing: -0.32px;
        color: $brand-dark-10;
    }

    img {
        border-radius: 10px;
        height: 269px;
        object-fit: cover;
    }

    &-nav {
        overflow-x: scroll;
        white-space: nowrap;
        @extend .custom-hide-scroll-bar;
        @include media-breakpoint-up(lg) {
            &::-webkit-scrollbar {
                display: none;
            }
            scrollbar-width: none;
        }

        li {
            color: $brand-grey-10;
            font-weight: $fw-500;
            cursor: pointer;

            &.active {
                color: $brand-dark-10;
                font-weight: $fw-bold;
            }
        }
    }


    &-row {
        margin: 41px 0;
        row-gap: 64px;
        overflow-x: scroll;
        display: grid;
        grid-template-columns: auto auto auto auto;
        @extend .custom-hide-scroll-bar;
        &::-webkit-scrollbar {
            &-track {
                box-shadow: inset 0 0 6px $brand-dark-10;
            }
            &-thumb {
                background-color: $brand-yellow;
                outline: 1px solid $brand-yellow;
            }
        }

        @include media-breakpoint-up(lg) {
            margin: 64px 0 80px;
            scrollbar-color: $brand-yellow $brand-dark-10;
        }

        .project {
            &-category {
                font-size: $font-sm;
                color: $brand-grey-10;
            }
            &-type {
                letter-spacing: -0.19px;
                color: $brand-dark-10;
            }
            &-card {
                width: 269px
            }
        }
    }

    &-btn {
        border-bottom: 1.01758px solid rgba(143, 143, 143, 0.4);
        font-size: 22px;
        border-radius: unset;
    }
}

.qualities {
    color: $brand-white-20;

    &-row {
        @include media-breakpoint-up(md) {
            padding: 0 40px;
        }
    }

    li {
        margin-bottom: 20px;
        line-height: 165%;
        
        @include media-breakpoint-up(md) {
            font-size: $font-size-18;
        }

        &:last-of-type {
            margin-bottom: 35px;
        }
        
        img {
            margin-right: 10px;
        }
    }

    &-heading {
        @extend .custom-heading-2;
        margin-bottom: 35px;
    }
}

.team {
    padding: 75px 0 25px;
    @include media-breakpoint-up(md) {
        padding: 75px 0 50px;
    }

    &-section {
        padding: 0 6px;
        @include media-breakpoint-up(md) {
            padding: 0 40px;
        }
    }

    &-heading {
        @extend .custom-heading-2;
        margin-bottom: 20px;
    }

    &-header {
        margin-bottom: 50px;
        @include media-breakpoint-up(md) {
            margin-bottom: 64px;
        }

        p {
            letter-spacing: 0.3px;
            color: $brand-white-20;
            @include media-breakpoint-up(md) {
                font-size: $font-size-20;
            }
        }
    }

    &-row {
        overflow-x: scroll;
        overflow-y: hidden;
        @extend .custom-hide-scroll-bar;
        @include media-breakpoint-up(lg) {
            overflow-x: hidden;
            padding-bottom: 0;
        }
    }

    &-card {
        width: 274px;
        @media screen and (min-width: 400px) {
            width: 384px;
        }

        img {
            object-fit: cover;
            border-radius: 8px;
            @include media-breakpoint-up(sm) {
                border-radius: unset;
            }
        }
    }

    &-member {
        &-position {
            padding-bottom: 30px;
        }

        top: 342px;
        background: rgba(0, 0, 0, 0.3);
        backdrop-filter: blur(24px);
        padding: 24px 24px 32px;
        transition: top 2s;
        right: 0;
        left: 0;
        &:hover {
            top: 175px;
            @include media-breakpoint-up(sm) {
                top: 200px;
            }
            transition: top 2s;
            height: 336px;
            .team-member-position {
                padding-bottom: 0;
            }
        }

        &-description {
            height: 110px;
            @include media-breakpoint-up(sm) {
                height: 96px;
            }
        }

        &-info {
            overflow-y: hidden;
        }

        &-name {
            font-size: $font-size-30;
        }

        &-link {
            height: fit-content;
        }

        &-socials {
            column-gap: 20px;
        }
    }

    .icon-circle {
        backdrop-filter: blur(8px);
        border-radius: 28px;
        width: 56px;
        height: 56px;
        background-color: $brand-white;
        border: 1px solid $brand-grey;

        i {
            color: $brand-grey-30;
            font-size: 28px;
        }

        &.disabled {
            pointer-events: none;
            background-color: $brand-grey-10;
            border: none;
        }
    }
}

.blog {
    padding: 50px 6px;
    @include media-breakpoint-up(md) {
        padding: 110px 0;
    }

    &-card {
        background: $brand-dark-10;
        border-radius: 9.6px;
        height: 100%;
        color: $brand-grey;
        padding: 25px 21px;
        @include media-breakpoint-up(sm) {
            padding: 34px 31px 31px;
        }

        .medium-feed {
            &-image,
            &-link {
               display: none
            }
            &-snippet {
                color: $brand-white;
            }
        }

        img {
            object-fit: cover;
        }

        p {
            font-size: $font-sm;
        }

        h3 {
            font-size: $font-size-18;
            line-height: 23px;

            @include media-breakpoint-up(sm) {
                font-size: 19px;
                line-height: 22px;
                letter-spacing: -0.48px;
            }
        }
        &-top {
            p {
                @include media-breakpoint-up(sm) {
                    font-size: unset;
                }
            }
            
            h3 {
                @include media-breakpoint-up(sm) {
                    font-size: 24px;
                    line-height: 30px;
                    letter-spacing: 0.8px;
                }
            }

            &-label {
                background: $brand-yellow;
                border-radius: 5px;
                padding: 6px 14px;
                font-size: $font-size-12;
                color: $brand-dark-10;
                position: absolute;
                right: 20px;
                top: 15px;
                @include media-breakpoint-up(md) {
                    top: 23px;
                }
            }
        }

        &-normal {
            img {
                width: 289px;
                height: 100%;
            }
            .blog-card-img-box {
                height: 230px;
            }
            @include media-breakpoint-up(sm) {
                padding: 24px;
            }
        }
    }

    &-view-all-btn {
        font-size: $font-size-12;
        letter-spacing: -0.5px;
        padding: 8px 20px;
        border-radius: 50px;

        @include media-breakpoint-up(md) {
            padding: 13px 38px;
            border-radius: 10px;
            font-size: $font-body;
        }
    }

    &-btn {
        background: $brand-grey-10;
        color: $brand-white;
        font-size: $font-sm;
        padding: 10px 30px;
        border-radius: 50px;
        &:hover {
            color: $brand-white;
        }

        @include media-breakpoint-up(md) {
            border-radius: 48px;
            padding: 9.6px 29px;
            font-size: $font-size-18;
        }
    }

    &-page {

        .blog-card {
            &-img {
                height: 250px;
            }
            &-body {
                height: 150px;
                overflow-y: hidden;
            }
        }

        img {
            width: 100%;
            max-height: 100%;
            height: unset;
        }

        .medium-feed {
            &-item {
                width: 100%;

                a {
                    color: $brand-yellow;
                }
            }
            &-snippet {
                color: $brand-white;
            }
        }
    }
}
